:root {
  --background-color: transparent;
  --thumb-color: #475569;
  --thumb-hover-color: #64748b;
}

.scrollbar::-webkit-scrollbar {
  background: var(--background-color);
  width: 15px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: 10px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: var(--thumb-color);
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: var(--thumb-hover-color);
}

@supports (scrollbar-color: #bbd2c5 #0f172a) {
  .scrollbar {
    scrollbar-color: var(--thumb-color) var(--background-color);
  }
}

.barrelroll {
  animation: roll 4s;
}

.fade {
  animation: fadeout 4s forwards;
}

@keyframes roll {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
